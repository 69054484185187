<template>
  <div>
    <b-row>
      <b-col>
        <product-group-card />
      </b-col>
      <b-col>
        <project-product-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <project-product-amount-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <project-lines-notes-card />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import ProjectLinesNotesCard from '@/views/Admin/Projects/Elements/Lines/Notes.vue'
import ProjectProductAmountCard from '@/views/Admin/Projects/Elements/Lines/amount.vue'
import ProductGroupCard from '@/views/Admin/Projects/Elements/Lines/ProductGroup.vue'
import ProjectProductCard from '@/views/Admin/Projects/Elements/Lines/Products.vue'

export default {
  name: 'ProjectAddLineForm',
  components: {
    ProjectProductCard,
    ProductGroupCard,
    ProjectProductAmountCard,
    ProjectLinesNotesCard,
    BRow,
    BCol,
  },
  computed: {
    dataItem() {
      return this.$store.getters['projectLines/dataItem']
    },
    productData() {
      return this.$store.getters['stocks/dataList']
    },
  },
}
</script>

<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card
        title="Düzenle"
      >
        <project-add-line-form />
      </b-card>
    </validation-observer>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :disabled="dataItem.submitData"
          @click="submitData"
        >
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProjectAddLineForm from '@/views/Admin/Projects/Components/AddLinesForm.vue'

export default {
  name: 'ProjectProductEdit',
  components: {
    ProjectAddLineForm,
    BCol,
    BCard,
    BRow,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projectLines/dataItem']
    },
    saveData() {
      return this.$store.getters['projectLines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.dataItem.submitStatus = false
        this.$router.push(`/app/projects/view/${this.dataItem.id_projects}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('projectLines/getDataItem', this.$route.params.id)
        .then(response => {
          if (response) {
            this.setProducts(response.id_product_groups)
          }
        })
    },
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.dataItem.items = this.projectLineItems
          this.$store.dispatch('projectLines/saveData', this.dataItem)
        }
      })
    },
    setProducts(idProductGroup) {
      if (idProductGroup) {
        this.$store.dispatch('products/getDataList', {
          select: [
            'products.id as id',
            'products.title as title',
            'products.pcode as pcode',
          ],
          where: {
            'products.id_product_groups': idProductGroup,
          },
        })
      } else {
        this.dataItem.id_products = null
      }
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>

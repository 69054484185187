<template>
  <b-form-group
    label="Ürün"
    label-for="id_products"
  >
    <validation-provider
      #default="{ errors }"
      name="Ürün"
      rules="required"
    >
      <v-select
        id="productGroup"
        v-model="dataItem.id_products"
        :options="products"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
        :disabled="!products.length"
      >
        <template v-slot:option="option">
          {{ option.pcode }} - {{ option.title }}
        </template>
        <template v-slot:selected-option="option">
          {{ option.pcode }} - {{ option.title }}
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'ProjectProductCard',
  components: { ValidationProvider, vSelect, BFormGroup },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projectLines/dataItem']
    },
    products() {
      return this.$store.getters['products/dataList']
    },
  },
  created() {
    localize('tr')
  },

}
</script>

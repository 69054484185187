<template>
  <b-form-group
    label="Ürün Grubu"
    label-for="id_product_groups"
  >
    <validation-provider
      #default="{ errors }"
      name="Ürün Grubu"
      rules="required"
    >
      <v-select
        id="id_product_groups"
        v-model="dataItem.id_product_groups"
        :options="productGroups"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
        @input="setProducts"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { localize, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'

export default {
  name: 'ProductGroupCard',
  components: { ValidationProvider, vSelect, BFormGroup },
  data() {
    return {
      required,
      productGroup: null,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['projectLines/dataItem']
    },
    units() {
      return this.$store.getters['units/dataList']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
  created() {
    localize('tr')
    this.getGroups()
  },
  methods: {
    getGroups() {
      this.$store.dispatch('productGroups/getDataList')
    },
    setProducts() {
      if (this.dataItem.id_product_groups) {
        this.dataItem.id_products = null
        this.$store.dispatch('products/getDataList', {
          select: [
            'products.id as id',
            'products.title as title',
            'products.pcode as pcode',
          ],
          where: {
            'products.id_product_groups': this.dataItem.id_product_groups,
          },
        })
      } else {
        this.dataItem.id_products = null
      }
    },

  },
}
</script>
